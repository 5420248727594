import React, { useState } from 'react';
import Contact from '../../templates/contact';
import './style.css';
import Family from '../../images/sobrenos_dariano.jpg';

function Sobre() {

    return (<div>
        <div className='container-sobrenos'>
            <img src={Family} className='image-sobrenos'></img>
            <div className='script-sobrenos'>
                <h1 className='title-sobrenos'>Sobre nós</h1>
                <div className='content-sobrenos'><p>A Dariano Seguros,
                    fundada há mais de cinco anos por Thalita e Débora
                    D’Ariano, começou como uma empresa familiar dedicada
                    a fazer a diferença na vida das pessoas. Com um
                    atendimento personalizado e um catálogo de mais de
                    20 produtos, incluindo Seguros Automotivos, Residencial
                    , Vida, Saúde e muito mais, protegemos o que você mais ama.
                    Reconhecida como referência no mercado, a Dariano Seguros
                    mantém seus valores de confiança e cuidado, oferecendo
                    segurança e tranquilidade a cada cliente.</p>
                </div>
            </div>
        </div>
        <div className='blockend-sobrenos'></div>

    </div>
    );
}

export default Sobre;