import WhatsappLogo from '../../images/icons/whatsapp.png';
import React, { useState } from 'react';
import './style.css';

function Whatsapp() {

    return (<div>
        <a className='whatsappButton whatsappRastreio' href='https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+
e+me+interessei%21+Gostaria+de+saber+mais.' target='_blank'>
            <img className='whatsappImage' src={WhatsappLogo} /></a>

    </div>
    );
}

export default Whatsapp;