import './style.css';

function Numeros() {
    return (<div className='container-numbers'>
        <div className='block-numbers'><p className='number-numbers'>+5</p><p className='sub-numbers'>anos no mercado</p></div>
        <div className='block-numbers'><p className='number-numbers'>+600</p><p className='sub-numbers'>vidas seguradas</p></div>
        <div className='block-numbers'><p className='number-numbers'>+400</p><p className='sub-numbers'>bens assegurados</p></div>
        <div className='block-numbers'><p className='number-numbers'>+20</p><p className='sub-numbers'>seguradoras parceiras</p></div>
    </div>
    );
}

export default Numeros;