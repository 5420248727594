import './style.css';
import vida from '../../images/icons/vida.png';
import odonto from '../../images/icons/odonto.png';
import carro from '../../images/icons/carro.png';
import casa from '../../images/icons/casa.png';
import saude from '../../images/icons/saude.png';
import vidaBranco from '../../images/icons/vida-branco.png';
import odontoBranco from '../../images/icons/odonto-branco.png';
import carroBranco from '../../images/icons/carro-branco.png';
import casaBranco from '../../images/icons/casa-branco.png';
import saudeBranco from '../../images/icons/saude-branco.png';
import capitalizacao from '../../images/icons/capitalizacao.png';
import consorcio from '../../images/icons/consorcio.png';
import empresarial from '../../images/icons/empresarial.png';
import financiamentoCarro from '../../images/icons/financiamentoCarro.png';
import investimentos from '../../images/icons/investimentos.png';
import previdenciaPrivada from '../../images/icons/previdenciaPrivada.png';
import responsabilidadeCivil from '../../images/icons/responsabilidadeCivil.png';
import seguroLocaticia from '../../images/icons/seguroLocaticia.png';
import seguroMoto from '../../images/icons/seguroMoto.png';
import seguroPortateis from '../../images/icons/seguroPortateis.png';
import seguroResidencial from '../../images/icons/seguroResidencial.png';
import seguroViagem from '../../images/icons/seguroViagem.png';
import capitalizacao_branca from '../../images/icons/capitalizacao_branca.png';
import consorcio_branca from '../../images/icons/consorcio_branca.png';
import empresarial_branca from '../../images/icons/empresarial_branca.png';
import financiamentoCarro_branca from '../../images/icons/financiamentoCarro_branca.png';
import investimentos_branca from '../../images/icons/investimentos_branca.png';
import previdenciaPrivada_branca from '../../images/icons/previdenciaPrivada_branca.png';
import responsabilidadeCivil_branca from '../../images/icons/responsabilidadeCivil_branca.png';
import seguroLocaticia_branca from '../../images/icons/seguroLocaticia_branca.png';
import seguroMoto_branca from '../../images/icons/seguroMoto_branca.png';
import seguroPortateis_branca from '../../images/icons/seguroPortateis_branca.png';
import seguroResidencial_branca from '../../images/icons/seguroResidencial_branca.png';
import seguroViagem_branca from '../../images/icons/seguroViagem_branca.png';


function Servicos() {
    return (<div className='content-marketing'>
        <h1 className='title-marketing'>Escolha a Dariano Seguros</h1>
        <h5 className='subcontent-marketing'> <span className='bold-marketing'>Estamos aqui para cuidar de você e do que mais importa na sua vida</span></h5>
        <div className='blockcard-marketing'>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >

                <div className='card-content-marketing'>
                    <img src={consorcio} className='icon-servicos icon-servicos-normal' alt="Seguro Saúde" />
                    <img src={consorcio_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Saúde" />
                    <h3 className='title-card-marketing'>Consórcio</h3>
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank">

                <div className='card-content-marketing'>
                    <img src={saude} className='icon-servicos icon-servicos-normal' alt="Seguro Odontologia" />
                    <img src={saudeBranco} className='icon-servicos icon-servicos-branco' alt="Seguro Odontologia" />
                    <h3 className='title-card-marketing'>Plano de Saúde</h3>
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >

                <div className='card-content-marketing'>
                    <img src={odonto} className='icon-servicos icon-servicos-normal' alt="Seguro de Vida" />
                    <img src={odontoBranco} className='icon-servicos icon-servicos-branco' alt="Seguro de Vida" />
                    <h3 className='title-card-marketing'>Plano Odontológico</h3>
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href='https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais.' target='_blank' >

                <div className='card-content-marketing'>
                    <img src={vida} className='icon-servicos icon-servicos-normal' alt="Seguro Auto" />
                    <img src={vidaBranco} className='icon-servicos icon-servicos-branco' alt="Seguro Auto" />
                    <h3 className='title-card-marketing'>Seguro de Vida</h3>
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >
                <div className='card-content-marketing'>
                    <img src={seguroViagem} className='icon-servicos icon-servicos-normal' alt="Seguro Residencial" />
                    <img src={seguroViagem_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Residencial" />
                    <h3 className='title-card-marketing'>Seguro Viagem</h3>
                </div>

            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >

                <div className='card-content-marketing'>
                    <img src={previdenciaPrivada} className='icon-servicos icon-servicos-normal' alt="Seguro Saúde" />
                    <img src={previdenciaPrivada_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Saúde" />
                    <h3 className='title-card-marketing'>Previdência Privada</h3>
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank">

                <div className='card-content-marketing'>
                    <img src={investimentos} className='icon-servicos icon-servicos-normal' alt="Seguro Odontologia" />
                    <img src={investimentos_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Odontologia" />
                    <h3 className='title-card-marketing'>Investimentos</h3>
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >

                <div className='card-content-marketing'>
                    <img src={responsabilidadeCivil} className='icon-servicos icon-servicos-normal' alt="Seguro de Vida" />
                    <img src={responsabilidadeCivil_branca} className='icon-servicos icon-servicos-branco' alt="Seguro de Vida" />
                    <h3 className='title-card-marketing'>⁠Responsabilidade Civil Profissional</h3>
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href='https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais.' target='_blank' >

                <div className='card-content-marketing'>
                    <img src={seguroPortateis} className='icon-servicos icon-servicos-normal' alt="Seguro Auto" />
                    <img src={seguroPortateis_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Auto" />
                    <h3 className='title-card-marketing'>Seguro Portáteis</h3>

                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >
                <div className='card-content-marketing'>
                    <img src={casa} className='icon-servicos icon-servicos-normal' alt="Seguro Residencial" />
                    <img src={casaBranco} className='icon-servicos icon-servicos-branco' alt="Seguro Residencial" />
                    <h3 className='title-card-marketing'>Seguro Residencial</h3>
                    {/* <p>Proteja seu patrimônio com nosso seguro residencial.
        <span className='bold-marketing'> Transforme sua casa em um verdadeiro lar seguro.</span></p> */}
                </div>

            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >

                <div className='card-content-marketing'>
                    <img src={seguroLocaticia} className='icon-servicos icon-servicos-normal' alt="Seguro Saúde" />
                    <img src={seguroLocaticia_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Saúde" />
                    <h3 className='title-card-marketing'>Seguro Fiança Locatícia</h3>
                    {/* <p>
        Garanta sua tranquilidade e bem-estar com o melhor plano de saúde.
        <span className='bold-marketing'> Cuide de você e de quem você ama!</span>
    </p> */}
                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank">

                <div className='card-content-marketing'>
                    <img src={capitalizacao} className='icon-servicos icon-servicos-normal' alt="Seguro Odontologia" />
                    <img src={capitalizacao_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Odontologia" />
                    <h3 className='title-card-marketing'>Capitalização</h3>
                    {/* <p>Proteja sua saúde bucal com nosso seguro odontológico completo.
        <span className='bold-marketing'> Sorria sem preocupações! </span>
    </p> */}

                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >

                <div className='card-content-marketing'>
                    <img src={empresarial} className='icon-servicos icon-servicos-normal' alt="Seguro de Vida" />
                    <img src={empresarial_branca} className='icon-servicos icon-servicos-branco' alt="Seguro de Vida" />
                    <h3 className='title-card-marketing'>Seguro Empresarial</h3>
                    {/* <p>A segurança de quem você ama não tem preço.
        <span className='bold-marketing'> Garanta um futuro tranquilo com nosso seguro de vida.</span>
    </p> */}

                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href='https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais.' target='_blank' >

                <div className='card-content-marketing'>
                    <img src={carro} className='icon-servicos icon-servicos-normal' alt="Seguro Auto" />
                    <img src={carroBranco} className='icon-servicos icon-servicos-branco' alt="Seguro Auto" />
                    <h3 className='title-card-marketing'>Seguro Auto</h3>
                    {/* <p>Com nosso seguro auto, você dirige com total segurança e confiança.
        <span className='bold-marketing'> Proteja seu veículo contra imprevistos.</span>
    </p> */}

                </div>
            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >
                <div className='card-content-marketing'>
                    <img src={seguroMoto} className='icon-servicos icon-servicos-normal' alt="Seguro Residencial" />
                    <img src={seguroMoto_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Residencial" />
                    <h3 className='title-card-marketing'>Seguro Moto</h3>
                    {/* <p>Proteja seu patrimônio com nosso seguro residencial.
        <span className='bold-marketing'> Transforme sua casa em um verdadeiro lar seguro.</span></p> */}
                </div>

            </a>
            <a className='card-marketing whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" >

                <div className='card-content-marketing'>
                    <img src={financiamentoCarro} className='icon-servicos icon-servicos-normal' alt="Seguro Saúde" />
                    <img src={financiamentoCarro_branca} className='icon-servicos icon-servicos-branco' alt="Seguro Saúde" />
                    <h3 className='title-card-marketing'>Financiamento Veícular</h3>
                    {/* <p>
        Garanta sua tranquilidade e bem-estar com o melhor plano de saúde.
        <span className='bold-marketing'> Cuide de você e de quem você ama!</span>
    </p> */}
                </div>
            </a>

        </div>
    </div >
    );
}

export default Servicos;