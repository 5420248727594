import './App.css';
import Header from './components/Header/index';
import Principal from './components/Principal';
import Servicos from './components/Servicos';
import Numeros from './components/Numeros';
import DownBar from './components/down-bar';
import Carrossel from './components/carrossel';
import Sobre from './components/sobre-nos';
import Whatsapp from './components/whatsapp';


function App() {
  return (<div>
    <Whatsapp></Whatsapp>
    <Header></Header>
    <div className='home-page'>
      <Principal></Principal>
      <Numeros></Numeros>
      <Carrossel></Carrossel>
      <Servicos></Servicos>
      <Sobre></Sobre>
      <DownBar></DownBar>
    </div>
  </div>
  );
}

export default App;