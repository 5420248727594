import React, { useState } from 'react';
import Contact from '../../templates/contact';
import './style.css';

function Principal() {

    return (<div className='body-traffic'>
        <div className='container-traffic'>
            <h1 className='title-traffic'>Proteja o que realmente importa com a nossa expertise em seguros</h1>
            <div className='content-traffic'>
                <p>Oferecemos soluções personalizadas para proteger seu patrimônio e garantir a tranquilidade da sua família, com uma abordagem simples e eficaz.</p>
            </div>
            <a className='button-traffic whatsappRastreio' href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" rel="noopener noreferrer">
                Falar com especialista
            </a>
        </div>
    </div>
    );
}

export default Principal;