import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import 'slick-carousel/slick/slick.css';
import './style.css';
import 'slick-carousel/slick/slick-theme.css';
import Aig from '../../images/seguros/AIG.png';
import Amil from '../../images/seguros/amil.png';
import Argo from '../../images/seguros/argo.png';
import Axa from '../../images/seguros/axa.png';
import Azos from '../../images/seguros/azos.png';
import Azul from '../../images/seguros/azul.png';
import Chubb from '../../images/seguros/chubb.png';
import Excelsior from '../../images/seguros/excelsior.png';
import Ezze from '../../images/seguros/ezze.png';
import Fairfax from '../../images/seguros/fairfax.png';
import Hdi from '../../images/seguros/hdi.png';
import Ituran from '../../images/seguros/ituran.png';
import Kovr from '../../images/seguros/kovr.png';
import Liberty from '../../images/seguros/liberty.png';
import Mag from '../../images/seguros/mag.png';
import Notredame from '../../images/seguros/notredame.png';
import Porto from '../../images/seguros/porto.png';
import Prevent from '../../images/seguros/prevent.png';
import Suhai from '../../images/seguros/suhai.png';
import Sulamerica from '../../images/seguros/sulamerica.png';
import TokioMarine from '../../images/seguros/tokiomarine.png';
import QSaude from '../../images/seguros/qsaude.png';
import Akad from '../../images/seguros/Akad.png';
import Allianz from '../../images/seguros/Allianz.png';
import Coris from '../../images/seguros/Coris.png';
import Fator from '../../images/seguros/Fator.png';
import Omint from '../../images/seguros/Omint.png';
import Unimed from '../../images/seguros/Unimed.png';


const Carrossel = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    if (isMobile) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  }, [isMobile]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10px'
  };

  const images = [
    Porto, Azul, Sulamerica, Coris, Amil, Omint, Prevent,
    Notredame, Unimed, Azos, Mag, Liberty, Hdi, Fairfax,
    Ituran, Allianz, TokioMarine, Akad, Chubb, Excelsior, Fator, Aig,
    Axa, Ezze
    // , Kovr, Argo, QSaude, Suhai
  ];

  const slideStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.6rem'
  };

  return (
    <div style={{ width: "94%", margin: "3rem 0" }}>
      <Slider {...settings}>
        {images.map((url, index) => (
          <div key={index} style={slideStyle}>
            <img src={url} alt={`Slide ${index + 1}`} className="carousel-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carrossel;