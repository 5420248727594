import logoSimples from '../../images/Logo_completo.png';
import darianoEscrito from '../../images/Dariano_escrito.png';
import React, { useState } from 'react';
import Contact from '../../templates/contact';
import './style.css';

function Header() {

    return (<header className='header'>
        <img className='image-header' src={logoSimples} alt="Logo D'ariano Seguros" />
        <div className='contact-header'>
            <a href="https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais." target="_blank" className='button-header link-header whatsappRastreio'>WhatsApp</a>
            {/* <a href='https://www.facebook.com.br/people/Dariano-Corretora-de-Seguros/100066892892766/?locale=fi_FI&_rdr' target="_blank" className='button-header link-header'>Facebook</a> */}
            {/* <a href='https://www.instagram.com/darianoseguros' target="_blank" className='link-header'>Instagram</a> */}
        </div>

    </header>
    );
}

export default Header;