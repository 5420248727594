import './style.css';
import logoCompleto from '../../images/Logo_completo.png';
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import whatsapp_preto from '../../images/icons/whatsapp_preto.png';

function DownBar() {
    return (<div className='downBar'>
        <div className='content-downBar'>
            <div className='diggio-downBar'>
                <img className='image-downBar' src={logoCompleto} alt="Logo D`ariano" />
            </div>
            <div className='contact-downBar'>
                <p className='contactTitle-downBar'>Contato</p>
                <div className='tel-downBar'>
                    <p>+55 11 97813-0772</p>
                </div>

                <div className='mail-downBar'>
                    <p>debora@dariano.net </p>
                </div>


            </div>
            <div className='social-downBar'>
                <a href='https://www.facebook.com.br/people/Dariano-Corretora-de-Seguros/100066892892766/?locale=fi_FI&_rdr' target='_blank' rel='noopener noreferrer'>
                    <img className='icon-downBar' src={facebook} alt="Página do facebook" />
                </a>
                <a href='https://www.instagram.com/darianoseguros' target='_blank' rel='noopener noreferrer'>
                    <img className='icon-downBar' src={instagram} alt="Página do instagram" />
                </a>
                <a href='https://wa.me/5511978130772?text=Ol%C3%A1%2C+vi+seus+servi%C3%A7os+pelo+site+e+me+interessei%21+Gostaria+de+saber+mais.' target='_blank' rel='noopener noreferrer' className='whatsappRastreio'>
                    <img className='icon-downBar' src={whatsapp_preto} alt="WhatsApp" />
                </a>
            </div>
        </div>

    </div>
    );
}

export default DownBar;